<template>
  <div
    class="min-h-screen w-full bg-joinwithus-background bg-cover bg-center flex flex-col justify-center items-center">
    <div
      class="container-principal w-full lg:w-4/5 xl:w-95p mx-auto flex flex-col lg:flex-row items-center justify-between text-left">
      <!-- Première section avec le titre, le paragraphe et le bouton -->
      <section class="w-full lg:w-1/2">
        <div class="mb-10-percent">
          <h1 class="text-4xl font-sans mb-4 text-secondary">
            Welcome to Quambio
          </h1>
          <p class="font-sans mb-4 text-secondary text-lg">
            We are a community of like-minded people, who care about the environment and who understand
            that our individual combined impact matters, whatever its size.<br><br>
            Join us to learn more about the climate, to share with others and to participate in mobilitiy
            challenges supported by great organisations and clubs.<br><br>
            We look forward to seeing you on our platform!
          </p>
          <CustomButton customClass="bg-accent text-primary mb-4 hover:text-black w-44" paddingX="px-12" href="#join">
            Join Us
          </CustomButton>
        </div>
        <div class="flex items-center">
          <img :src="networkPeople" alt="Network people" class="mr-4 users" />
          <p class="font-sans text-secondary text-lg">
            460K+ People Join Our Teams
          </p>
        </div>
      </section>

      <!-- Deuxième section avec l'image "Network" -->
      <section class="w-full lg:w-1/2 p-4 flex items-center justify-center">
        <img :src="network" alt="Network" class="w-full max-h-4/5 object-contain" />
      </section>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/elements/CustomButton.vue";
import networkPeople from '@/assets/images/NetworkPeople.svg';
import network from '@/assets/images/Network.svg';

export default {
  name: "JoinWithUs",
  components: {
    CustomButton,
  },
  methods: {
    handleClick(event) {
      console.log("Button clicked!", event);
    },
  },
  computed: {
    isActive() {
      return (page) => {
        return this.$route.path.includes(page) ? 'active' : '';
      };
    }
  },
  data() {
    return {
      networkPeople,
      network,
    }
  }
};
</script>

<style scoped>
a:hover {
  color: #00FFCC;
}

a {
  position: relative;
}

.marker {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: transparent;
  /* Par défaut, invisible */
}

.marker.active {
  background-color: #00FFCC;
}

.container-principal {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.custom-button-login {
  background-image: linear-gradient(to bottom right, #05485C, #045F6B);
  color: #ffffff;
  padding: 0.2rem 0.5rem;
}

.mb-10-percent {
  margin-bottom: 10%;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .xl\:w-95p {
    width: 95%;
  }

  .container-principal {
    padding-top: 7rem;
  }

  .mb-10-percent {
    margin-bottom: 2rem;
  }
}

@media (max-width: 599px) {
  .xl\:w-95p {
    width: 90%;
  }

  .container-principal {
    padding-top: 7rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.875rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .users {
    max-width: 30%;
  }
}
</style>